import moment from "moment";
import React, { useEffect, useState } from "react";
import { PmsGridSort } from "../../Util/PmsHelper";
import MaterialDataGrid from "../Common/MaterialDataGrid";

export default function EdenWbWebScheduledList({ tableData }) {
  const [rowData, setRowData] = useState([]);
  const tableColumn = [
    {
      field: "coverKey",
      headerName: "CoverKey",
      width: 150,
      sortComparator: PmsGridSort,
    },
    {
      field: "locKey",
      headerName: "LocKey",
      width: 160,
      sortComparator: PmsGridSort,
    },
    {
      field: "apptKey",
      headerName: "ApptKey",
      width: 170,
      sortComparator: PmsGridSort,
    },
    {
      field: "result",
      headerName: "Result",
      width: 280,
      sortComparator: PmsGridSort,
    },
    {
      field: "resultDetails",
      headerName: "ResultDetails",
      width: 230,
      sortComparator: PmsGridSort,
    },
    {
      field: "createdDate",
      headerName: "CreatedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
    {
      field: "lastModifiedDate",
      headerName: "LastModifiedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
  ];

  useEffect(() => {
    if (tableData.length === 0) return setRowData([]);

    const data = [];

    tableData.forEach((n, i) => {
      const { lastModifiedDate } = n;
      if (lastModifiedDate === undefined || null) return;
      
      return data.push({
        ...n,
        id: i,
        lastModifiedDate: moment(lastModifiedDate).format(
          "MM/DD/YYYY hh:mm:ss A"
        ),
        createdDate: moment(lastModifiedDate).format("MM/DD/YYYY hh:mm:ss A"),
      });
    });

    setRowData(data);
  }, [tableData]);

  return (
    <>
      <label>Write Back Status from last 10 days</label>
      <MaterialDataGrid
        tableHeight={350}
        tableColumns={tableColumn}
        tableData={rowData}
        onSelectedRow={null}
      />
    </>
  );
}
