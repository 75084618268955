import CryptoJS from "crypto-js";
import Constants from '../constants';
export function Encrypt(text)
{
    let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_EK);
    const fixedIv = process.env.REACT_APP_IV;
    const ivString = CryptoJS.enc.Utf8.parse(fixedIv);
  

   
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: ivString,
    }).toString();

    return encrypted;
}