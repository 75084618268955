import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUIContext } from '../../ContextLib/contextHooks';
import Constants from '../../constants';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { Redirect } from 'react-router-dom';
import Storage from '../../storage';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

export default function ActivePatients() {
  let componentId = 'activePatients';
  let queryType = 'coverkey';
  const ui = useUIContext(componentId);
  const [searchInput, setSearchInput] = useState('');
  const [showLoginData, setShowLoginData] = useState(false);
  const [isActivePatient, setIsActivePatient] = useState(false);
  const [activePatientCount, setActivePatientCount] = useState(0);

  const componentDidMount = async () => {
    const user = Storage.getItem(Constants.currUserKey);
    let redirectToLogin = false;
    if (user && user.jwt) {
      try {
        ui.ShowOverlay();
        const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        ui.HideOverlay();
      } catch (error) {
        ui.ShowSnackbar(error, Severity.error);
        redirectToLogin = true;
      }
    } else {
      redirectToLogin = true;
    }

    if (redirectToLogin) return <Redirect to={{ pathname: '/' }} />;
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  const onSubmit = async () => {
    try {
      ui.ShowOverlay();
      var request1 = {
        params: {
          secretCode: `${process.env.REACT_APP_API_KEY}`,
          queryType: queryType,
          queryString: searchInput,
        },
      };
      const url1 = `${process.env.REACT_APP_COVERLOOKUP_API}/getCoverLevelData`;
      const res1 = await axios.get(url1, request1);

      var request = {
        params: {
          secretCode: `${process.env.REACT_APP_API_KEY}`,
          coverKey: searchInput,
        },
      };

      const url = `${process.env.REACT_APP_PATIENT_API}/GetActivePatientsCount`;
      const res = await axios.get(url, request);
      let d = res.data;

      if (res1?.data.length > 0) {
        if (d) {
          setIsActivePatient(true);
          setActivePatientCount(d);
        } else {
          ui.ShowSnackbar('No active patients found for coverKey.', Severity.warning);
          setIsActivePatient(false);
        }
      } else {
        ui.ShowSnackbar('Cover does not exist in the database.', Severity.warning);
      }
    } catch (error) {
      ui.ShowSnackbar('Invalid search parameter! Please update search value and try again.', Severity.error);
      setIsActivePatient(false);
    }
    setShowLoginData(false);
    ui.HideOverlay();
  };

  const renderSearchText = () => {
    return (
      <TextValidator
        style={{ width: '170px', height: '30px' }}
        id='searchInput'
        autoComplete='off'
        placeholder='Search Value'
        value={searchInput}
        onInput={(e) => setSearchInput(e.target.value)}
        validators={['required']}
        errorMessages={['Search Value is required']}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderActivePatients = () => {
    return (
      <div>
        <Box p={4} bgcolor='background.paper'>
          <div>
            {isActivePatient ? (
              <Grid container justify='flex-start' alignItems='center' spacing={2}>
                <Grid item>
                  <Typography>Active patient count in SDB: </Typography>
                </Grid>

                <div>
                  <Grid item>
                    <Typography>{activePatientCount}</Typography>
                  </Grid>
                </div>
              </Grid>
            ) : null}
          </div>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Box p={4} bgcolor='background.paper'>
        <div className='coverlookup-data'>
          <ValidatorForm onSubmit={onSubmit} onError={(errors) => console.log(errors)}>
            <Grid container justify='flex-start' alignItems='center' spacing={2} className='search-option'>
              <Grid item>
                <Typography variant='h6'>Cover Key: </Typography>
              </Grid>

              <div className='search-fields'>
                <Grid item>{renderSearchText()}</Grid>
              </div>

              <Grid item>
                <Button style={{ maxWidth: '100px', minWidth: '100px' }} variant='contained' color='primary' type='submit'>
                  SEARCH
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>

          <Divider />
          {renderActivePatients()}
        </div>
      </Box>
    </>
  );
}
