import React, { Component, useState, useEffect } from 'react';
import { Redirect, useParams, useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Overlay from '../../Components/Overlay/Overlay';
import './Forgot.scss';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import MainLogo from '../../Assets/Images/4patient_logo-trans_New.png';
import moment from 'moment';
import { Encrypt } from '../../Services/EncryptService';

function Reset() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newUserId, setNewUserId] = useState();
    const params = useParams();
    let history = useHistory();

    const _onSubmit = async () => {
        if (password !== "" && confirmPassword !== "") {
            if (password === confirmPassword) {
                const apiKey = `${process.env.REACT_APP_API_KEY}`;
                const talkData = {
                    PasswordHash: Encrypt(password),
                    UserId: Encrypt(newUserId),
                };
                const url = `${process.env.REACT_APP_ADMIN_API}/updateUserPassword?secretCode=${apiKey}`;
                const res = await axios.post(url, talkData);
                window.location = `/?returnUrl=${window.location.href}`;
            }
            else {
                setPasswordError("Password and Confirm Password does not match");
            }
        }
        else if (confirmPassword === "" && password === "") {
            setPasswordError("Password/ConfirmPassword is required");
        }
        else if (password === "") {
            setPasswordError("Password is required");
        }
        else if (confirmPassword === "") {
            setPasswordError("Confirm Password is required");
        }


    };

    const PasswordInput = (password) => {
        setPassword(password)
    };

    const ConfirmPasswordInput = (confirmPassword) => {
        setconfirmPassword(confirmPassword)
    };
    useEffect(() => {
        checkingID();
    }, [])
    const checkingID = async () => {
        const userId = params.id;
        const urlId = params.urlId;
        setNewUserId(userId);
        const encodedUserId = encodeURIComponent(Encrypt(userId));
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const url = `${process.env.REACT_APP_ADMIN_API}/GetLinkDate/${encodedUserId}?secretCode=${apiKey}`;
        const res = await axios.get(url);
        checkExpiration(res, userId);
    }
    const backToLogin = () => {
        window.location = `/?returnUrl=${window.location.href}`
    }
    const checkExpiration = (response, userId) => {
        const email = response.data.email;
        if (response.data.userId === userId) {
            const linkDate = moment(response.data.linkDate).format('MM/DD/YYYY HH:mm:ss');
            const systemDate = moment().utc().format('MM/DD/YYYY HH:mm:ss');

            var date1 = new Date(linkDate);
            var date2 = new Date(systemDate);

            // calculate days/hours
            let diffInMilliSeconds = Math.abs(date2 - date1) / 1000
            const days = Math.floor(diffInMilliSeconds / 86400);
            const time = (diffInMilliSeconds / 3600 % 24).toFixed(2);

            if (days > 0) {
                history.push(`/emaillink/${email}`);
            }
            if (parseFloat(time) > parseFloat("2.00")) {
                history.push(`/emaillink/${email}`);
            }
        }
        else {
            history.push(`/emaillink/${email}`);
        }
    }

    return (
        <>
            <ValidatorForm onSubmit={_onSubmit} onError={errors => console.log(errors)}>
                <div className="login">
                    <section className="login__header">

                        <div className="login-header">
                            <div className="login-header__logo-cont">
                                <img
                                    alt="Site Logo"
                                    src={MainLogo} />
                            </div>
                        </div>

                    </section>
                    <section className="login__content">
                        <div className="login-norm">

                            <h1 className="login-norm__title">{'4PC Admin Page'}</h1>
                            <h3 className="login-norm__subtitle">{'Reset Password'}</h3>
                            <form className="login-norm__form">
                                <TextField
                                    className="login-norm__input"
                                    inputProps={{
                                        style: {
                                            padding: '12px 14px'
                                        }
                                    }}
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                    value={password}
                                    autoComplete="current-password"
                                    variant="outlined"
                                    onInput={(e) => PasswordInput(e.target.value)}
                                />
                                <span className={`login-norm__error login-norm__error--no-margin ${'' ? 'login-norm__error--visible' : ''}`}>{''}</span>              <p />
                                <p />
                                <TextField
                                    className="login-norm__input"
                                    inputProps={{
                                        style: {
                                            padding: '12px 14px'
                                        }
                                    }}
                                    id="outlined-password-input"
                                    label="Confirm Password"
                                    type="password"
                                    value={confirmPassword}
                                    autoComplete="current-password"
                                    variant="outlined"
                                    onInput={(e) => ConfirmPasswordInput(e.target.value)}
                                />
                                <span className={`login-norm__error login-norm__error--no-margin ${passwordError ? 'login-norm__error--visible' : passwordError}`}>{passwordError}</span>              <p />
                                <p />
                                <div className="reset-button">
                                    <button
                                        className="login-norm__login_button"
                                        disabled={false}
                                        type="button"
                                        onClick={() => backToLogin()}
                                    >Back
                                    </button>
                                    <br />
                                    <button
                                        className="login-norm__login_reset_btn"
                                    disabled={false}
                                    type="submit">
                                    {'Submit'}
                                </button>


                                </div>
                            </form>
                            <Overlay show={false}>
                                <i className="spinner-eclipse"></i>
                            </Overlay>
                        </div>
                    </section>
                </div>
            </ValidatorForm>
        </>
    );
}
export default Reset